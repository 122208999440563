.image-button{
	-webkit-transition: all 0.5s 0s ease;
	-moz-transition: all 0.5s 0s ease;
	-o-transition: all 0.5s 0s ease;
	transition: all 0.5s 0s ease;
	height: 2rem;
	margin: 1rem 1rem 0; 
}

.image-button:hover {
	opacity: 0.5;
}