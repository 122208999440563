.image-row {
    text-align: center;
}
.image-box {
    width: 30%;
    padding: 1%;
    margin: .5%;
    display: inline-block;
    font-size: 0; /* fixes bottom padding */
}
.image-box img {
    max-width: 100%;
	max-height: 100px;
}