html {
  --color-background: rgb(40, 44, 52);
  --color-background-darker: rgb(28, 32, 39);
  --color-text: rgb(0, 184, 255);
  --color-text-dark: rgb(0, 102, 255);
  --font-main: 'Roboto';
  --font-size: 18px;
  --box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.4);
  font-size: var(--font-size);
  font-family: var(--font-main);
  background-color: var(--color-background);
}

img {
  border-radius: 5px;
}

.App {
  text-align: center;
  color: var(--color-text);
  background-color: var(--color-background);
}

.card-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.navbar {
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-dark .navbar-brand {
  color: var(--color-text);
  font-family: var(--font-main);
  font-weight: bold;
}

.navbar-dark .navbar-brand:hover{
  color: var(--color-text-dark);
}

.navbar-dark .navbar-brand:focus{
  color: var(--color-text);
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: var(--color-text);
}

.navbar-dark .navbar-nav .nav-link.active{
  color: var(--color-text);
}

.product-card {
  width: 25rem;
  padding: 0.5rem;
  background-color: var(--color-background-darker);
  box-shadow: var(--box-shadow);
  margin: 10px;
  width: 400px;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--color-text);
}

.dropdown-item {
  color: var(--color-text);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--color-text-dark);
}

.navbar-dark .navbar-nav .show>.nav-link {
  color: var(--color-text-dark);
}

.dropdown-item {
  color: var(--color-text);
}

.dropdown-item:hover {
  color: var(--color-text-dark);
  background-color: var(--color-background-darker);
}

.dropdown-menu {
  background-color: var(--color-background-darker);
}

@media (min-width: 992px){
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
}
}

.btn-primary {
  background-color: var(--color-text);
  border-color: var(--color-text);
  font-family: var(--font-main);
  font-weight: bold;
  margin: 0 10px;
}

.btn-primary:hover{
  background-color: var(--color-text-dark);
  border-color: var(--color-text-dark);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}